.order-list{
  padding-left: 20px !important;
  padding-right: 20px !important;

  ion-breadcrumbs{
    padding-left: 0;
    padding-right: 0;
  }

  ion-title[size=large]{
    text-align: left;
    font-weight: 400 !important;
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 8px;
    padding-left: 0;
    margin-top: 16px;
    display: block;
    position: static;
  }

  ion-list-header{
    padding-left: 0;
  }

  ion-title[size=small]{
    text-align: left;
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 8px;
    padding-left: 0;
    margin-top: 16px;
    display: block;
    position: static;
  }
}