.empty-list{
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 110px;

  h2{
    font-weight: 400 !important;
    font-size: 28px !important;
    line-height: 1.2;
    margin-bottom: 12px !important;
    display: block;
    position: static;
    height: auto;
    padding: 0;
    text-align: center;
    padding-left: 0 !important;
  }

  .text{
    margin-bottom: 24px;
    display: block;
    color: #57595A;

     p{
      margin: 0;
      font-size: 14px;
      line-height: 1.5;
     }
  }

  ion-button{
    text-transform: none;
  }

  ion-icon{
    width: 60px;
    height: 60px;
  }
}