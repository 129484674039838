@use "sass:map";
@import "src/theme/variables.scss";

.scroll-top{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    &__footer__text{
        margin-right: map.get($spacers, 2);
    }
    &__footer {
      padding: map.get($spacers, 3) map.get($spacers, 2);
      width: 100%;
      @media (max-width: map.get($breakpoints, "sm")) {
        padding: map.get($spacers, 2) map.get($spacers, 6);
      }
      ion-button{
        font-size: toRem(12);
        text-align: center;
        margin: auto;
        min-width: 220px;
        @media (max-width: map.get($breakpoints, "sm")) {
            min-width: 100%;
        }
      }
      ion-icon{
        --ion-color-primary: white;
      }

      background-color: $gray-100;
      display: flex;
      justify-content: end;
      @media (max-width: map.get($breakpoints, "sm")) {
          display: block;
          ion-button{
              width: 100%;
              color: white;
          }
      }
  }

}
