@use "sass:map";
@import "../../../../../theme/variables.scss";

.explore-links {
  padding-bottom: 20px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  @media (min-width: map.get($breakpoints, "md")) {
    padding-left: 32px;
    padding-right: 32px;
  }

  .link-image {
    width: 100%;
  }

  .image-link {
    width: 100%;
    text-align: justify;
    padding: 0px 0 20px 0;
    font-size: 18px;
    display: block;
  }

  .link-image-container {
    padding: 20px 0;

  }

  .link-icon-container {
    display: flex;
    flex-direction: row;
  }

  .links-section {
    display: block;
    flex-direction: row !important;
    color: #000;
    margin: auto;
    width: 100%;
  }

  .link-text {
    color: black;
  }
  .link-list {
    margin: auto;
    width: 100%;
  }

  .icon-link {
    text-align: justify;
    padding: 6px 0px;
    font-size:16px;
    display: block;
    width: 100%;
  }

  ion-icon {
    vertical-align: bottom;
    padding-right: 5px;
  }

  ion-title {
    text-align: left;
    padding: 0;
    font-weight: 400 !important;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
    margin-bottom: 8px;
    display: block;
    position: static;
  }

  .swiper{

    .swiper-slide{
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
    }
  }

  .swiper:not(.swiper-initialized) {

    .swiper-wrapper{

      transform: none;
      display: block;
    }
  }

  .slider-buttons{
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 9;
    padding-left: 16px;
    padding-right: 16px;
    height: 0;

    > .button {
      margin: 0;
      height: 34px;
      width: 34px;

      &::part(native) {
        padding: 0;
        height: 34px;
        width: 34px;
        border: 1px solid #202020;
        border-radius: 150px;
        background: #202020;
      }

      &:last-child{
        float: right;

        ion-icon{
          left: 61%;
        }
      }
    }

    ion-icon {
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  ion-item{
    --inner-padding-end: 0;
    &::part(native) {
      padding-left: 0;
    }
  }
}
