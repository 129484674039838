@use "sass:map";
@import "../../../theme/variables.scss";

.search-page {
    padding: map.get($spacers, 7);
    max-width: 1220px;
    margin: auto;
    @media (max-width: map.get($breakpoints, "md")) {
        padding: map.get($spacers, 5);
    }
    @media (max-width: map.get($breakpoints, "sm")) {
        padding: map.get($spacers, 4);
        padding-top: map.get($spacers, 5);
    }
    .icon-large{
        width: 50px;
        height: 50px;
        top: 25px;
        @media (max-width: map.get($breakpoints, "sm")) {
            width: 25px;
            height: 25px;
            top: 10px;
        }
    }
    .native-input{
        font-size: 48px;
        &::placeholder{
            color: #DADCDD;
        }
        @media (max-width: map.get($breakpoints, "sm")) {
            font-size: 24px;
        }      
    }
    .recommended-search{
        margin-top: map.get($spacers, 10);
        @media (max-width: map.get($breakpoints, "sm")) {
            margin-top: map.get($spacers, 5);
        } 
    }

    .search-field{
        position: relative;
        margin-bottom: 10px;
        
        @media (min-width: map.get($breakpoints, 'md')) {
            min-width: 328px;
        }
    
        ion-label{
            font-size: 20px !important;
            color: #ACAEAF !important;
        }
    
        ion-input{
            font-size: 20px;
            font-weight: 400;
            --padding-start: 0;
    
            input{
                padding-left: 0px !important;
                @media (min-width: map.get($breakpoints, 'md')) {
                    font-size: 24px;
                    &::placeholder {
                        font-size: 24px;
                        color: #ACAEAF;
                    }
                }
            }
        }
    
        ion-item::part(native){
            padding-left: 0;
            @media (min-width: map.get($breakpoints, 'md')) {
                border-bottom-width: 2px;
            }
        }
    
        ion-item{
            --border-color: #000;
        }
    
        ion-icon{
            position: absolute;
            top: 15px;
            right: 0;
            z-index: 999;
            font-size: 27px;
            @media (min-width: map.get($breakpoints, 'md')) {
                margin-top: -5px;
            }
        }
    
        .icon-btn{  
        
            ion-icon  {
                position: relative;
                font-size: 27px;
                margin: auto;
                top: 0;
                min-width: 25px;
            }
            .search-button {
                position: absolute;
                top: 40%;
                right: 0;
                color: #858585;
                width:25px !important;
                min-width: 25px;
                height:25px;
            }
            
            .search-field  {
                position: relative;
                font-size: 27px;
                margin: auto;
                top: 0;
                min-width: 25px;
            }
        }
    }
}

