@use 'sass:map';
@import '../../../../../theme/variables.scss';

.side-menu {
  text-transform: unset;
  font-weight: 400;
  .close-icon {
    cursor: pointer;
    ion-icon {
      font-size: 36px !important;
    }
  }
  ion-item {
    --background-hover: white;
  }
  .button-clear {
    background-color: white;
    --background-hover: white;
    font-weight: 400;
  }
  .icon-larger {
    font-size: 36px !important;
  }

  .menu-button {
    text-transform: none;
    font-weight: 600;
  }

  .menu-list {
    color: $primary;
    &:hover {
      color: $gray-500;
    }
  }

  .large-item {
    display: block;
    width: 100%;
    cursor: pointer;
    line-height: 64px;
    color: inherit;
    font-size: 48px;
    margin-bottom: map.get($spacers, 2);
    background-size: 100% 0.1em, 0 0.1em;

    &:hover {
      color: $gray-500;
      background-color: white;
      text-decoration: underline;
      text-underline-offset: 10px;
      text-decoration-thickness: 3px;
      background-size: 0 0.1em, 100% 0.1em;
    }
  }

  .small-item {
    font-size: 20px;
    display: block;
    width: 100%;
    cursor: pointer;
    line-height: 36px;

    &::part(native) {
      border-bottom: none;
    }
  }

  .list-container {
    margin-top: 100px;
  }

  .page-header {
    padding: 10px;
    display: flex;

    .ion-button {
      text-transform: none;
      font-weight: 400;
    }
  }
  .top-button {
    .animation {
      text-transform: unset;
      position: relative;
      &::before {
        transition: 0.2s;
        position: absolute;
        bottom: -5px;
        left: 0;
        content: ' ';
        height: 1px;
        background-color: $primary;
        width: 0;
      }
      &:hover {
        background-color: white;
        &::before {
          width: 100%;
        }
      }
    }
  }

  .segments{
    --background: white;
  }

  ion-button {
    height: 45px;
    text-transform: unset;
    font-size: 1rem;
    font-weight: 400;
  }

  .icon-search {
    font-size: 1rem;
  }

  ion-segment {
    --min-height: 32px;

    justify-content: flex-start;
    width: auto;
    margin-left: 1.5rem;
  }

  ion-segment-button {
    --indicator-height: 1.5px;
    --background-hover: none;
    --background-focused: none;
    --min-width: 0;
    --padding-end: 0;
    --padding-start: 0;
    --ripple-color: transparent;

    width: auto;
    flex: 0 0 auto;
    min-height: 0;
    min-width: 0;
    padding: 0;
    margin-right: 1.5rem;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
