@use 'sass:map';
@import '../../../theme/variables.scss';

.app-header {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  &::after {
    content: inherit;
  }

  ion-toolbar{
    padding-left: map.get($spacers, 2);
    padding-right: map.get($spacers, 2);
    justify-content: space-between;
    padding-top: 13px !important;
    padding-bottom: 13px;
    --background: rgba(255, 255, 255, 0.9);
    background: white;
    .toolbar-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .buttons-last-slot {
      justify-content: flex-end;
    }

    & > * {
      flex: 1;
    }
    @media screen and (max-width: map.get($breakpoints, 'md')) {
      padding-left: map.get($spacers, 1);
      padding-right: map.get($spacers, 1);
    }
  }

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  &::after {
    content: inherit
  }

  position: relative;
  .icon-inner {
    color: $gray !important;
  }
  .toolbar-container {
    overflow: visible;
    content: unset;
  }
  .dropdown-button {
    position: fixed;
    background: white;
    color: $primary;
    padding-left: 0;
    z-index: 99999;
    border: 1px solid $primary;
    border-radius: 17px;
    width: 250px;
    height: auto;
    overflow: hidden;
    padding-bottom: map.get($spacers, 3);
    @media (max-width: map.get($breakpoints, 'sm')) {
      top: unset !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
      height: 250px;
    }
    .close-button {
      display: none;
      cursor: pointer;
      @media (max-width: map.get($breakpoints, 'sm')) {
        display: block;
      }
    }

    li {
      padding: map.get($spacers, 2) map.get($spacers, 3);
      list-style: none;
      font-size: 0.875rem;
      @media (max-width: map.get($breakpoints, 'sm')) {
        font-size: 1rem;
      }
      &.dropdown-button-item {
        cursor: pointer;
        &:hover {
          background-color: $gray-200;
        }
      }
      &.user-name {
        font-size: 1rem;
        @media (max-width: map.get($breakpoints, 'sm')) {
          font-size: 1.25rem;
          font-weight: 600;
        }
        line-height: 1;
        font-weight: bolder;
        margin-top: map.get($spacers, 2);
        margin-bottom: map.get($spacers, 1);
        display: flex;
        color: $primary;
        justify-content: space-between;
        align-items: center;
      }
    }
    hr {
      background-color: $gray-200;
      width: 90%;
      @media (max-width: map.get($breakpoints, 'sm')) {
        display: none;
      }
    }
  }

  &__anon {
    height: 177px;
    border-top: 8px #000 solid;
    &::after {
      content: inherit;
    }

    & > ion-toolbar {
      background: transparent;
    }

    & + ion-router-outlet {
      border-top: 8px #000 solid;
    }
  }

  ion-button {
    text-transform: unset;
    &.header-button {
      &:hover {
        background-color: transparent;
        --background-hover: transparent;
      }
    }
  }
  .icon-header {
    margin-left: map.get($spacers, '2');
  }
  .sc-ion-buttons-md-s ion-icon[slot='end'] {
    @media screen and (max-width: map.get($breakpoints, 'lg')) {
      margin-left: 0;
    }
  }

  .button-native {
    position: relative;
    background: $primary;
    color: white;
    border-radius: 50px;
    font-weight: 900;
    padding: 16px 10px;
    height: 49px;
    font-size: 14px;
  }

  .header-button {
    .animation {
      position: relative;
      &::before {
        transition: 0.2s;
        position: absolute;
        bottom: -5px;
        left: 0;
        content: ' ';
        height: 1px;
        background-color: $primary;
        width: 0;
      }
      &:hover {
        background-color: white;
        &::before {
          width: 100%;
        }
      }
    }
  }
  .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: map.get($spacers, 2);
  }
  .user-icon-header {
    margin-top: 2px;
  }
  .hidden-on-table-up {
    display: block;
    @media screen and (min-width: map.get($breakpoints, 'lg')) {
      display: none;
    }
  }
  .show-on-table-up {
    display: none;
    @media screen and (min-width: map.get($breakpoints, 'sm')) {
      display: block;
    }
  }
  .hidden-on-desktop {
    @media screen and (min-width: map.get($breakpoints, 'lg')) {
      display: none;
    }
  }
  .hidden-on-tablet {
    @media screen and (max-width: map.get($breakpoints, 'lg')) and (min-width: map.get($breakpoints, 'sm')) {
      display: block;
    }
  }
  .show-on-desktop {
    display: none;
    @media screen and (min-width: map.get($breakpoints, 'lg')) {
      display: block;
      &.user-info {
        display: flex;
      }
    }
  }

  .menu-logo {
    max-width: 108px;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }

  ion-button.semi-hidden {
    visibility: hidden;
  }

  ion-icon,
  ion-menu-button {
    font-size: 27px;
  }

  .cart-badge-number {
    position: absolute;
    top: 0;
    right: -7px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 150px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    line-height: 120%;
    padding-bottom: 2px;
    z-index: 999;
  }

  .custom-notification {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e7fae4;
    width: 100%;
    z-index: 99999;
    padding-top: 32px;
    padding-bottom: 16px;
    padding-left: 18px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.no-icon {
      padding-left: 24px;
    }

    .flex-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
    }

    .notification-message {
      display: flex;
      justify-content: center;
      align-items: center;

    }

    p {
      margin: 0;
      display: inline-block;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
    }

    ion-button {
      display: inline-block;
      text-transform: none;
    }

    ion-icon {
      margin-right: 8px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      font-size: 56px;
    }

    .progress-bar {
      position: absolute;
      background-color: #000000;
      height: 4px;
      bottom: 0;
      left: 0;
    }
  }
  .user-added-notification{
    @media screen and (min-width: map.get($breakpoints, 'md')) {
      padding-top: 20px;
      position: fixed;
      top: unset;
      max-height: 66px;
      bottom: 20px;
      right: 20px;
      left: unset;
      max-width: 370px;
    }
}
  .total-discount {
    text-align: center;
    background-color: #f8d950;
    color: #000;
    padding: 7px;
    margin: 0;
    font-weight: 400;
    font-size: 14px;

    &.over {
      background-color: #ab3f1d;
      color: #ffffff;
    }
  }
}

.profile-header {
  position: relative;
  // contain: content;
  .profile-button {
    display: flex;
    background: transparent;
    color: $primary;
    font-size: 1rem;
    align-items: center;
    .ion-icon {
      font-size: 1.2rem;
    }
  }
  .dropdown-button {
    display: block;
    position: fixed;
    border: 1px solid $primary;
    inset: 0px auto auto 0px;
    margin: 0px;
    padding: 0;
    width: 120px;
    min-width: 100%;
    transform-origin: top right;
    list-style: none;
    background-color: white;
    z-index: 2;
    border-radius: 20px;
    &.open {
      display: block;
    }
  }
  @media (max-width: map.get($breakpoints, 'md')) {
    padding-left: 40px;
  }
  @media (max-width: map.get($breakpoints, 'sm')) {
    padding-left: 0;
  }
}
