* {
    font-family: 'SF Pro Display' !important;
    letter-spacing: .1px;
}

ion-app{
    background-color: #ffffff;
}

ion-button.button-disabled{
    opacity: 1;
    color: #ACAEAF;

    &::part(native) {
        background-color: #F6F6F8;
        box-shadow: none;
        color: #ACAEAF;
        border-color: #F6F6F8;
    }
}

ion-router-outlet{

    &.not-authed{
        margin-top: 0;
    }
}

.page-with-menu{
    
    > ion-content > div:first-child:not(.empty-list) {
        margin-top: 82px !important;
    }
}

.filters{
    margin-bottom: 24px;

    ion-button{
        width: 100%;
        text-transform: none;
        font-size: 14px;
    }

    ion-button::part(native){
        padding-left: 0;
        padding-right: 0;
        border: 1px solid black;
        box-shadow: none;
    }
}

.iframe-wrapper{
    width: 100%;
    display: block;
    height: 100vh;

    iframe{
        width: 100%;
        height: 100%;
        border: none;
    }
}

p.error{
    color: #DA1414;
    font-size: 14px;
    margin-top: 3px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.no-border-toolbar{

    &:after{
        display: none;
    }

    ion-toolbar{
    --border-color: transparent !important;
    --border-width: 0px !important;
    }
}

.hidden{
    display: none !important;
}

ion-button{

    &.ion-color-secondary, &.ion-color-secondary.ion-activated{
        --ion-color-tint: var(--ion-color-secondary, #ffffff) !important;
        --ion-color-shade: var(--ion-color-secondary, #ffffff) !important;
    }
}

.hidden-image{
    opacity: 0;
}
.swiper .swiper-slide {
    height: auto !important;
}

// iOS specific
.plt-ios {

    .pwa-modal{

        h2{
            padding-left: 17px;
        }
    }
}