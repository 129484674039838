@use 'sass:map';
@import 'src/theme/variables.scss';

.product{
  margin: 0;
  box-shadow: none;
  border-radius: 0;

  ion-card-header{
    padding: 16px;
    background-color: #F6F6F6;
    // background-color: red;
  }

  ion-grid{
    padding: 0;
    --ion-grid-padding: 0;
  }

  .padding-right{
    padding-right: 20px;
  }

  .padding-left{
    padding-left: 20px;
  }

  .product-image{
    height: 166px;
    object-fit: contain;
    @media (min-width: map.get($breakpoints, 'md')) {
      height: 256px;
    }
    
    @media (min-width: map.get($breakpoints, 'lg')) {
      height: 360px;
    }
  }

  h3{
    font-size: 14px;
    text-decoration: underline;
    color: #000000;
    line-height: 180%;
  }

  ion-text{
    color: #57595A;
    font-size: 14px;
    line-height: 1.3em;
  }
}