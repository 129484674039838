.summary-item{
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #DADCDD;
    margin: 0;
    margin-bottom: 10px;

    ion-card-header{
        padding: 0;
    }

    ion-badge{
        --ion-color-base: #DADCDD !important;
        font-size: 14px;
        font-weight: 400;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 4px;
        color: #000;
    }

    ion-button{
        text-transform: none;

        &::part(native){
            border: 1px solid #000;
            border-radius: 20px;
            padding: 8px 24px;
            height: 34px;
            font-size: 12px;
        }
    }

    .image-col{
        background-color: #F6F6F6;
    }

    .info-col{
        padding-left: 16px;
    }

    .action-buttons{
        align-items: flex-end;
        display: flex;

        ion-router-link{
            margin-bottom: 14px;
            margin-left: 16px;
            font-size: 12px;
            text-decoration: underline;
            display: inline-block;
            cursor: pointer;
        }
    }

    h3{
        color: #000;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
    }

    .product-number{
        font-size: 12px;
        font-weight: 400;
        color: #989B9C;
        margin-top: 0;
        margin-bottom: 4px;
    }

    .info-item{
        text-align: right;

        p{
            margin-top: 0;
            margin-bottom: 0;
            display: inline-block;
            vertical-align: top;

            &.name{
                font-size: 12px;
                font-weight: 400;
                color: #57595A;
                margin-top: 8px;
                margin-right: 5px;
            }
    
            &.amount{
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                color: #000;
            }
        }
    }

    strong{
        color: #000;
    }

    .embellishment-display{
        margin-bottom: 0;
        padding-top: 16px;
        padding-bottom: 16px;

        ion-col{
            padding-bottom: 3px;
            padding-top: 3px;
        }

        ion-row{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .summary-row{

        ion-col{
            padding-bottom: 24px;
        }

        .info-item.total-price{
            margin-bottom: 5px;
        }

        ion-button{
            height: 36px;

            &::part(native) {
                height: 36px;
            }
        }
    }
}