@use "sass:map";
@import "../../../../../theme/variables.scss";

.announcements {
  padding: 24px;
  padding-bottom: 32px;

  .swiper{
    margin-left: 0;
    margin-right: 0;
  }

  .announcements-container{
    background-color: #fdf3c5;
    @media (min-width: map.get($breakpoints, "md")) {
      display: flex;
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  .slide-wrapper{
    padding: 16px;
    width: 100%;
    @media (min-width: map.get($breakpoints, "md")) {
      display: flex;
      padding-left: 32px;
      padding-bottom: 24px;
      padding-top: 24px;
    }
  }

  .announce-text {
    width: 100%;
    font-size: 16px;
    background-color: #fdf3c5;
    text-align: left;
    overflow: hidden;
    overflow-wrap: break-word;
    line-height: 150%;
  }

  .announce-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 14px;
    line-height: 150%;
    @media (min-width: map.get($breakpoints, "md")) {
      width: 192px;
      min-width: 192px;
      margin-bottom: 0;
    }
  }

  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    padding-bottom: 16px;
    @media (min-width: map.get($breakpoints, "md")) {
      width: 148px;
      min-width: 148px;
      padding-right: 32px;
      align-items: flex-start;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    ion-button{
      margin: 0;
      height: auto;

      &::part(native){
        padding: 0;
      }

      &.button-disabled::part(native) {
        background-color: transparent;
        color: #000;
      }

      &.button-disabled{
        color: #000;
        opacity: .3;
      } 
    }
  }

  .nav-text {
    font-size: 16px;
    padding-left: 7px;
    padding-right: 7px;
    text-align: center;
    @media (min-width: map.get($breakpoints, "md")) {
      margin-top: 2px;
    }
  }
}
