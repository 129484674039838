@use 'sass:map';
@import '../../../../../theme/variables.scss';

.email-code-form {
	.ion-margin-top {
		--border-radius: 25px;
    font-weight: 400;
		font-size: 12px;
		margin-top: 30px;
		text-transform: none;
	}
}