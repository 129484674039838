.no-access{
    .brand-logo{
        max-width: 202px;
        width: 100%;
        display: block;
        margin: 0 auto;
        margin-top: 27px;
        margin-bottom: 75px;
    }

    .brand-image{
        width: 100%;
        height: 180px;
        object-fit: cover;
    }

    p{
        max-width: 327px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        color: #000000;
    }
}