/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 1440px,
  xl: 1920px
);

$spacer: 1rem;
$fontSize: 1rem;

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8,
);

$primary: #000000;
$secondary: #ffffff;

@function toRem($value) {
  $rem: ($value / 16) + rem; 
  @return $rem;
}

$gray: #959595;
$gray-100: #F6F6F8;
$gray-200: #DADCDD;
$gray-300: #ACAEAF;
$gray-400: #989B9C;
$gray-500: #57595A;
$gray-dark: #525252;

$red: #DA1414;

:root {
  /** primary **/
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 146, 148, 156;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #808289;
  --ion-color-primary-tint: #9d9fa6;

  /** secondary **/
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #808289;
  --ion-color-primary-tint: #9d9fa6;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #DA1414;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-light: #989B9C;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** light **/
  --ion-color-light: #DADCDD;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** grid **/
  --ion-grid-width-md: map.get($breakpoints, 'md');
  --ion-grid-width-lg: map.get($breakpoints, 'lg');
}
