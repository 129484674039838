@use "sass:map";
@import "./variables.scss";

.pwa-modal{
    --border-radius: 16px;
    --backdrop-opacity: .48;
    --ion-backdrop-color: #1F2937;

    @media (min-width: map.get($breakpoints, "md")) {
        ion-content{
            position: relative;
        }
    }

    ion-toolbar{
        padding-top: 16px !important;
        --background: transparent;
    }

    ion-input{

        input{
            padding-left: 13px !important;
        }
    }

    &.product-detail-modal{
        @media (min-width: map.get($breakpoints, "md")) {
            --height: 587px;
        }
        @media (min-width: map.get($breakpoints, "lg")) {
            --height: 659px;
        }

        ion-header{
            background-color: #F6F6F8;
        }

        .columns > ion-button{
            position: absolute;
            top: 5px;
            right: 0px;

            &::part(native){
                border: none;
                background-color: transparent;
            }

            &:hover{
                background-color: transparent;

                &::part(native){
                    background-color: transparent;

                    &:after{
                        display: none;
                    }
                }   
            }
        }

        .product-info{
            padding: 25px;
            padding-bottom: 0;

            @media (min-width: map.get($breakpoints, "lg")) {
                padding-top: 60px;
            }
            
            h2{
                font-size: 28px;
                font-weight: 400;
                padding-left: 0;
                margin-top: 0;
                margin-bottom: 4px;
            }
            
            p{
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                margin-top: 0;
                margin-bottom: 4px;
                line-height: 150%;

                span{
                    text-decoration: underline;
                }

                &.item-number{
                    color: #57595A
                }

                &.item-price{
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 14px;
                    margin-bottom: 0;
                    line-height: 30px;
                }
            }

            .border-wrapper{
                padding-bottom: 16px;
                border-bottom: 1px solid #F6F6F8;
            }
        }

        .product-description{
            padding: 25px;
            padding-bottom: 28px;

            h3{
                font-size: 20px;
                font-weight: 400;
                padding-left: 0;
                margin-top: 0;
                margin-bottom: 16px;
            }

            p, li{
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                margin-top: 0;
                margin-bottom: 4px;
                line-height: 150%;
            }

            ul{
                padding-left: 26px;
            }
        }

        .product-image{
            background-color: #F6F6F8;
            padding: 25px;
            padding-bottom: 60px;
            padding-top: 60px;
        }

        @media (min-width: map.get($breakpoints, "md")) {

            .columns{
                display: flex;
                height: 100%;
            }

            .right, .left{
                flex: 0 0 50%;
                max-width: 50%;
                
                .product-image{
                    height: 100%;

                    ion-img{
                        height: 100%;
                    }
                }
            }

            &::part(content){
                min-width: 767px;
                max-width: map.get($breakpoints, 'md');
                width: 100%;
            }
        }

        .left{
            @media (min-width: map.get($breakpoints, "md")) {
                padding: 25px;
            }
            @media (min-width: map.get($breakpoints, "lg")) {
                padding: 60px;
                padding-right: 24px;
            }
        }
    }

    &.sort{
  
        .filter-action-sheet{
          padding: 10px;
          max-height: 340px;
          overflow: scroll;
          position: relative;
      
          ion-item{
              cursor: pointer;
          }
      
          .title{
              position: relative;
              font-weight: bold;
              padding-top: 10px;
              padding-bottom: 24px;
              
              p{
                  margin: 0;
              }
      
              .close-icon{
                  position: absolute;
                  top: 0;
                  right: 0;
                  cursor: pointer;
              }
          }
      
          &.facet-action-sheet{
              padding: 24px;
              height: 100vh;
              max-height: 80vh;

              @media (min-width: map.get($breakpoints, "md")) {
                height: 34rem;
              }
      
              .title{
                  padding-bottom: 0;
              }

              .search-field{
                padding-left: 0;
                padding-right: 0;
              }
          }
      }
    }
  
    &.warning{
  
        h2{
            color: #DA1414;
        }
  
        .text {
            font-size: 16px;
            font-weight: 600;
            margin-top: 16px;
            margin-bottom: 0;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
  
    &.remove{
  
        h2{
            color: #DA1414;
        }
    }
  
    &.form-modal{
        @media (min-width: map.get($breakpoints, "md")) {
            --width: 606px;
            height: 90%;

            &::part(content) {
                height: 90%;
            }
        }

        .desktop-row{
            @media (min-width: map.get($breakpoints, "md")) {
               display: flex;

               .desktop-col{
                    -webkit-box-flex: 0;
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-left: 12px;
                    padding-right: 12px;
               }
            }
        }

        .label-container{
            display: flex;
            justify-content: space-between;

            ion-label{
                margin-bottom: 0 !important;
            }
        }

        .section{
            margin-bottom: 24px;
            @media (min-width: map.get($breakpoints, "md")) {
                padding-right: 12px;
                padding-left: 12px;
            }

             h2{
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                padding-left: 0;
             }
        }

        .split-inputs{
            display: flex;

            .state{
                width: 66.66666%;
                margin-right: 16px;
            }

            .zip{
                width: 33.333333%;
            }
        }
  
        .address-form{
            padding: 24px;
            padding-top: 0;
            position: relative;
            @media (min-width: map.get($breakpoints, "md")) {
                padding-right: 12px;
                padding-left: 12px;

                .intro{
                    @media (min-width: map.get($breakpoints, "md")) {
                        padding-right: 12px;
                        padding-left: 12px;
                    }
                }
            }
    
            .input-wrapper{
                margin-bottom: 20px;
  
                ion-label{
                    font-size: 14px;
                    color: #57595A;
                    font-weight: 400;
                    margin-bottom: 8px;
                    display: block;
                }
            }
    
            ion-select{
                margin-top: 10px;
            }
    
            ion-input, ion-textarea {
                border: 1px solid #989B9C;
                border-radius: 8px;
                color: #000;
                margin-top: 10px;
    
                &.has-focus, &.has-value{
                    border-color: #000;
                }
                
                input{
                    padding-left: 13px;
                    height: 34px;
                }

                textarea{
                    height: 128px;
                    padding-left: 13px;
                    padding-right: 13px;
                    @media (min-width: map.get($breakpoints, "md")) {
                        height: 199px;
                    }
                }

                &.error-found{
                    border-color: #DA1414;
                }
            }
        }
  
        .error-text{
  
            p{
                font-size: 14px;
                font-weight: 400;
                color: #DA1414;
                line-height: 21px;
                margin-top: 0;
            }
        }
    }
  
    h2{
        color: #000;
        font-weight: 600;
        font-size: 20px;
        padding-left: 24px;
        margin: 0;
    }
  
    .choices{
  
        ion-router-link{
            width: 100%;
            display: block;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
  
            &.selected{
                font-weight: bold;
            }
        }
  
        ion-item{
            cursor: pointer;
  
            ion-router-link{
                width: auto;
                display: initial;
                padding-left: 9px;
            }

            ion-icon{
                margin-right: 2px;
            }
        }
    }

    .modal-actions{
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 124px;
        width: 100%;
        padding: 24px;
        background-color: #F6F6F8;

        @media (min-width: map.get($breakpoints, "md")) {
            text-align: right;
            height: auto;
        }

        ion-button{
            width: 100%;
            text-transform: none;
            @media (min-width: map.get($breakpoints, "md")) {
                width: auto;
                display: inline-block;
                height: 34px;
                margin: 0;
                margin-right: 8px;

                &:last-child{
                    margin-right: 0;
                }
            }

            &::part(native){
                border: 1px solid #000;
                border-radius: 20px;
                padding: 8px 24px;
                height: 34px;
                font-size: 12px;
                @media (min-width: map.get($breakpoints, "md")) {
                    padding-left: 24px;
                    padding-right: 24px;
                    width: auto;
                }
            }

            &.button-disabled{

                &::part(native){
                    border: none;
                }
            }
        }
    }

    &.success-modal{

        ion-button{
            height: 34px;
        }

        .modal-actions{
            height: auto;
            position: absolute;
        }
    }
    
    .empty-list{
        margin-top: 80px;
        margin-bottom: 70px;

        h2{
            color: #000;
            font-weight: 600 !important;
            font-size: 20px !important;
            line-height: 150% !important;
            margin-bottom: 4px !important;
        }
    }

    .success-wrapper{

        .modal-actions{
            height: auto;
            @media (min-width: map.get($breakpoints, "md")) {
                position: absolute;
            }

            ion-button{
                height: auto;
            }
        }
    }

    &.info{

        h2{
            font-weight: 400;
        }
        
        p{
            padding-left: 24px;
            padding-right: 24px;
            font-weight: 400;
            font-size: 14px;
            margin-top: 0;
        }
    }

    ion-footer {
        background-color: $gray-100;
        display: flex;
        justify-content: end;
        @media (max-width: map.get($breakpoints, "sm")) {
            display: block;
            ion-button{
                width: 100%;
            }
        }

        ion-button{
            width: 100%;
            text-transform: none;
            font-weight: 400;

            &::part(native){
                border: 1px solid #000;
                border-radius: 20px;
                padding: 8px 24px;
                height: 34px;
                font-size: 12px;
                @media (min-width: map.get($breakpoints, "md")) {
                    padding-left: 24px;
                    padding-right: 24px;
                    width: auto;
                }
            }
        }
    }
}