@use "sass:map";
@import "../../../../theme/variables.scss";

.breadcrumb-wrapper{
  background-color: white;
  margin: 0 24px;
  &.product-style{
    .ion-breadcrumbs {
      background-color: white;
    }
    padding-left: 0;
    @media (max-width: map.get($breakpoints, "md")) {
      padding-left: 0;
    }
  }

}


  
ion-breadcrumbs {
  width: 100%;
  padding-top: 8px;
  padding-left: 0;
  padding-bottom: 5px;
  white-space: nowrap;
  flex-wrap: nowrap;

  ion-breadcrumb {
    color: #525252;
    font-size: 14px;

    &.breadcrumb-disabled {
      opacity: 1;
    }
    
    &::part(native) {
      max-width: 177px;
      padding-right: 8px;
      padding-left: 8px;
    }

    &:first-child::part(native){
      padding-left: 0;
    }

    &::part(separator) {
      transform: rotate(73deg);
    }

    &.ios{

      &::part(separator) {
        transform: none;
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        width: 0;
        &:after{
          content: "—";
          position: absolute;
          top: -5px;
          left: -7px;
          width: 15px;
          height: 2px;
          color: #677483;
        }
      }
    } 

    &:last-child{
      color: #989B9C;

      .breadcrumb-text{
        text-decoration: none;
      }
    }
  }

  .crumb-icon{
    color: #000 !important;
  }

  ion-breadcrumb[href='#'] {
    .breadcrumb-text {
      cursor: default;
    }
  }

  .breadcrumb-text{
    white-space: nowrap;
    text-decoration: underline;
    font-weight: 400;
    &.last {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      max-width: 125px;
    }
    &.skipLast {
      max-width: 167px;
    }
  }
}

.product-style ion-breadcrumbs{
  background-color: white;
}

