
.forgot-password-form {
	h1 {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
    margin-top: 20px;

    ion-icon,
    span {
      display: inline-block;
      vertical-align: top;
    }

    ion-icon {
      margin-top: 2px;
    }
  }

	.support-link {
		display: block;
		font-size: 14px;
		margin: 1.5rem auto 16px;
		text-align: center;
		width: 100%;
	}
}

