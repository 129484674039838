@use 'sass:map';
@import '../../../theme/variables.scss';

.text-input {
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  ion-input {
    border: solid 1px $gray-400;
    border-radius: 10px;

    input {
      border: 1px;
      border-width: 2px;
      color: black;
      padding-left: 13px !important;
    }
  }

  ion-label.input-label {
    color: $gray-500;
    padding-bottom: map.get($spacers, 2);
    font-size: 14px;
  }

  ion-label.required-label {
    float: right;
    transform: initial !important;
  }

  ion-label.input-label {
    color: $gray-500;
    padding-bottom: map.get($spacers, 2);
    font-size: 14px;
  }

  ion-label.required-label {
    float: right;
    transform: initial !important;
  }
}