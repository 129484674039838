@use "sass:map";
@import "../../../theme/variables.scss";

.login-layout {
  display: flex;
  flex-wrap: wrap;
  .brand-hero {
    order: 2;
    flex: 1;
    min-height: 100vh;
    color: var(--ion-color-primary);
    @media (max-width: map.get($breakpoints, "md")) {
      order: 1;
      min-width: 100%;
      height: 228px;
      min-height: auto;
    }
  }
  .brand-logo {
    max-width: 202px;
    width: 100%;
    display: block;
    margin: 0 auto; 
    margin-top: 27px;
    margin-bottom: 30px;
  }
  .form-content {
    order: 1;
    width: 40%;
    min-width: 420px;
    max-width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    @media (max-width: map.get($breakpoints, "md")) {
      order: 2;
      min-width: 100%;
      height: auto;
      padding: 20px;
    }
  }
  .center-content {
    width: 100%;
  }
}
