@use 'sass:map';
@import 'src/theme/variables.scss';

.facet-group {

	&>div:first-child {
		padding: 19px 0;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;

		@media (min-width: map.get($breakpoints, 'md')) {
			padding: 9px 0;
		}
	}

	ion-checkbox {
		margin-right: 11px;
		margin-top: 0;
		margin-bottom: 0;
		--border-color-checked: #000;
		--border-color: #000;
		--border-width: 1px;
	}

	ion-label {
		margin: 0;
	}

	ion-item {
		--min-height: 33px;
	}

	h3 {
		font-size: 16px;
		font-weight: 400;
		margin: 0;
		width: 100%;
		cursor: pointer;
		line-height: 16px;

		@media (min-width: map.get($breakpoints, 'md')) {
			font-size: 18px;
			line-height: 1.5;
		}
	}

	ion-icon {
		cursor: pointer;
	}

	.options {
		transition: all .24s ease-in-out;
		height: auto;
		max-height: 0;
		overflow: hidden;
		&--expanded {
			max-height: 100%;
		}
	}
}