@use 'sass:map';
@import 'src/theme/variables.scss';

.filter-options {
  max-height: 67%;
  height: 100%;
  overflow-y: auto;

  .facet-toggle {
    margin: 0.5rem 0 1rem 1.25rem;
    color: #57595A;
    font-size: 14px;

    ion-text {
      color: #57595A;
      border-bottom: 1px #57595A solid;
      cursor: pointer;
      display: inline;
    }
  }
}

.filter-actions {
  width: 100%;
  margin-top: 8px;
  left: 0px;

  ion-button {
    width: 100%;
    text-transform: none;
    font-size: 14px;
    height: 34px;
  }

  ion-button::part(native) {
    padding-left: 0;
    padding-right: 0;
    border: 1px solid black;
    box-shadow: none;
    height: 34px;
  }
}

.plt-ios {

  .filter-actions {
    bottom: 70px;
  }
}

.search-field{
  position: relative;
  margin-bottom: 10px;
  
  @media (min-width: map.get($breakpoints, 'md')) {
      min-width: 328px;
  }

  ion-label{
      font-size: 20px !important;
      color: #ACAEAF !important;
  }

  ion-input{
      font-size: 20px;
      font-weight: 400;
      --padding-start: 0;

      input{
          padding-left: 0px !important;
          @media (min-width: map.get($breakpoints, 'md')) {
              font-size: 24px;
              &::placeholder {
                  font-size: 24px;
                  color: #ACAEAF;
              }
          }
      }
  }

  ion-item::part(native){
      padding-left: 0;
      @media (min-width: map.get($breakpoints, 'md')) {
          border-bottom-width: 2px;
      }
  }

  ion-item{
      --border-color: #000;
  }

  ion-icon{
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 999;
      font-size: 27px;
      @media (min-width: map.get($breakpoints, 'md')) {
          margin-top: -5px;
      }
  }

  .icon-btn{  
  
      ion-icon  {
          position: relative;
          font-size: 27px;
          margin: auto;
          top: 0;
          min-width: 25px;
      }
      .search-button {
          position: absolute;
          top: 40%;
          right: 0;
          color: #858585;
          width:25px !important;
          min-width: 25px;
          height:25px;
      }
      
      .search-field  {
          position: relative;
          font-size: 27px;
          margin: auto;
          top: 0;
          min-width: 25px;
      }
  }
}