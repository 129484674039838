@use "sass:map";
@import "../../../../../theme/variables.scss";

.slider {
  background-color: #202020;
  height: 668px;
  position: relative;
  @media (min-width: map.get($breakpoints, "md")) {
    height: 743px;
  }

  @media (min-width: map.get($breakpoints, "lg")) {
    height: calc(88vh - 100px);
  }

  ion-card {
    padding: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%  !important;    

    display: flex !important;
    flex-direction: column  !important;
    height: 596px;
    background-color: #202020;

    @media (min-width: map.get($breakpoints, "md")) {
      height: 671px;
    }
    
    @media (min-width: map.get($breakpoints, "lg")) {
      height: calc(88vh - 100px);
      display: block !important;
    }

    &.has-video{
      overflow: hidden;
      position: relative;
      background-color: transparent;

      ion-card-header {
        overflow: hidden;
        height: 100%;

        a{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.one-slide{

    ion-card, .sliders-wrapper{
      height: 656px;
      @media (min-width: map.get($breakpoints, "md")) {
        height: 731px;
      }
      @media (min-width: map.get($breakpoints, "lg")) {
        height: 100%;
      }
    }
  }

  .sliders-wrapper{
    display: flex;
    flex-direction: column;
    height: 596px;
    // background-color: #202020;
    @media (min-width: map.get($breakpoints, "md")) {
      height: 671px;
    }

    @media (min-width: map.get($breakpoints, "lg")) {
      height: 100%;
      display: block;
    }

    .image-slider{
      display: flex;
      flex-grow: 1;
      height: 596px;

      @media (min-width: map.get($breakpoints, "md")) {
        height: 671px;
      }

      @media (min-width: map.get($breakpoints, "lg")) {
        height: 100%;
      }

      .swiper{
        width: 100%;
      }

      ion-card-header {
        display: block;
        max-height: 100%;
        align-content: center;

        ion-img, ion-router-link{
          width: 100%;
          height: 100%;
    
          &::part(image) {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      
      video{
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
      }
    }

    .text-slider{
      text-align: left;
      display: none;
      width: 100%;
      @media (min-width: map.get($breakpoints, "lg")) {
        display: block;
      }
    }

    .bottom-area{
      @media (min-width: map.get($breakpoints, "md")) {
        .controls{
          width: 277px;
        }
      }

      @media (min-width: map.get($breakpoints, "lg")) {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 9;

        ion-card{
          height: auto !important;
          background-color: transparent;

          ion-card-content{
            background-color: transparent;
          }
        }

        .background{
          width: 562px;
          background: rgba(0, 0, 0, .8);
          padding: 32px 48px;
          transition: .5s background;

          &.no-copy{
            background: transparent;
          }
        }

        .controls{
          padding-bottom: 0;
          padding-top: 16px;
          padding-left: 0px;
          padding-right: 0px;
          width: 277px;
          position: static;
          left: initial;
          bottom: initial;
          background-color: transparent;
        }
      }

      .container{
        height: auto;
        max-width: 1440px;
        margin: 0 auto;
      }
    }
  }

  ion-button.shop-button {
    color: black;
    height: 49px;
    font-weight: 100;

    &::part(native) {
      height: 49px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .cta-container {
    display: flex;
    flex-direction: row;

    ion-button{
      margin-right: 16px;
    }
  }

  .slide-content {
    display: block;
  }

  ion-card-header {
    padding: 0;    
    display: flex;
    flex-grow: 1;
    overflow: auto;

    @media (min-width: map.get($breakpoints, "lg")) {
      max-height: none;
      flex-grow: initial;
      display: block;
    }
  }

  ion-button {
    text-transform: none;
    font-weight: bold;
    --border-radius: 25px;
  }

  ion-card-header + .card-content-md {
    padding-top: 24px;
  }

  ion-card-content {
    background-color: #202020;
    text-align: left;
    padding: 24px;
    padding-bottom: 18px;

    @media (min-width: map.get($breakpoints, "lg")) {
      padding: 0;
    }
  }

  ion-title {
    text-align: left;
    padding: 0;
    font-weight: 400 !important;
    font-size: 40px !important;
    line-height: 48px;
    color: #ffffff;
    margin-bottom: 8px;
    position: static;
    align-items: flex-start;
    height: auto;
  }

  ion-text {
    line-height: 21px;
    font-size: 14px;
    display: block;
    margin-bottom: 24px;
    text-align: left;
  }

  ion-router-link.ion-color-secondary {
    text-decoration: underline;
  }

  .text-link {
    align-items: center;
    display: flex;
  }

  .controls {
    background-color: #202020;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    margin-top: auto;
    position: absolute;
    width: 100%;
    bottom: 0px;

    ion-text{
      line-height: 24px;
      font-weight: 700;
      font-size: 16px;
    }

    > .button {
      margin: 0;
      height: 34px;
      width: 34px;

      &::part(native) {
        padding: 0;
        height: 34px;
        width: 34px;
        border: 1px solid #ffffff;
        border-radius: 150px;
      }

      &:last-child{
        &::part(native) {
          padding-left: 5px;
        }
      }
    }

    ion-icon {
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .slider-timer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      ion-text {
        margin-bottom: 0;
      }
    }
  }
}
