@use "sass:map";
@import "src/theme/variables.scss";

.error-text{
    color: $red;
}

.pwa-modal-layout {
    --border-radius:0;
    line-height: 21px;
    font-size: 0.85rem;
    text-transform: unset;
    @media (max-width: map.get($breakpoints, "sm")) {
        --height: 90%;
        align-items: flex-end;
        --border-radius:15px;
    }
    ion-input {
        border: 1px solid $primary;
        border-radius: 8px;
        --padding-top: 6px;
        --padding-bottom: 6px;
    }
    &--full-screen{
        --width: calc(100vw - 64px);
        --height: calc(100vh - 64px);

        ion-content {--overflow: hidden}
        @media (max-width: map.get($breakpoints, "md")) {
            --height: calc(100vh);
            --width: calc(100vw);
            --border-radius:0;
            padding: unset;
        }
    }
    &--small {
        --height: 80%;
    }
    ion-footer {
        background-color: $gray-100;
        display: flex;
        justify-content: end;
        @media (max-width: map.get($breakpoints, "sm")) {
            display: block;
            ion-button{
                width: 100%;
            }
        }
    }
    .title{
        font-size: toRem(20);
        font-weight: 400;
    }


    .padding-control{
        padding: map.get($spacers, 3);
        padding-bottom: 0;
        ion-toolbar {
            --padding-start: 0;
            --padding-end: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .label-stacked.sc-ion-label-md-h{
            transform: unset;
        .input-wrapper {
            &.is-invalid {
                ion-input {
                    border: 1px solid $red;
                }
            }
        }
    }
    
    .label-container {
        display: flex;
        justify-content: space-between;
        font-size: toRem(14);
        transform: unset;
        color: $gray-500;
        margin-bottom: 0;
    }
    .header {
        display: flex;
        background-color: white;
        --background: white;
    }
    ion-button{
        text-transform: unset;
        font-size: toRem(12);
        font-weight: 400;
        padding-bottom: 0!important;
    }

}