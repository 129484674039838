@use 'sass:map';
@import '../../../../../theme/variables.scss';

.drop-ship__header {
  h2 {
    font-size: toRem(20);
    margin-bottom: 0;
    margin-top: 0;
  }
}

.error-text {
  color: $red;
  font-size: toRem(14);
}

.drop-ship {
  .instructions {
    font-style: italic;
    text-align: center;
    font-size: toRem(16);
    font-weight: 600;
    margin-bottom: 16px;
  }
  .error-text {
    color: $red;
    font-size: toRem(14);
  }
  &__alert-area {
    margin-bottom: map.get($spacers, 3);
  }

  .label-container {
    font-size: 14px;
    margin-bottom: 0;
  }
}
