@use 'sass:map';
@import '../../../theme/variables.scss';
.input-wrapper{
    .sc-ion-input-ios {
        padding-left: 13px;
        padding-right: 13px;
    }
    ion-input, ion-select{
        --padding-top: 6px;
        --padding-bottom: 7px;

    }
    .select-icon-inner{
        display: none;
    }
    ion-select{
        border: 1px solid $primary;
        border-radius: 20px;
        .select-icon-inner{
            display: none;
        }

        &::part(icon) {
            opacity: 1;
            color: white;
            &::after{
                position: relative;
                opacity: 0;
                opacity: 1;
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                content:" ";
                z-index: 2;
                transform: rotate(45deg);
            }
    
        }
    }

    &--is-invalid{
        ion-input, ion-select {
            border: 1px solid $red;
        }
    }
}